import { CHANGE_LECTURE_SESSION_ID } from 'publisher/actionTypes'

export const setLectureSessionId = id => ({
  type: CHANGE_LECTURE_SESSION_ID,
  payload: id,
})

export const unsetLectureSessionId = {
  type: CHANGE_LECTURE_SESSION_ID,
  payload: null,
}
